import PropTypes from 'prop-types';
import React, { useState, useEffect, useCallback } from 'react';

import { Icon } from '@powdr/components';
import { useSeason } from '@powdr/hooks';
import { getLevelLink, getLevelName, isLevelLinkNormal } from '@powdr/utils';

import {
  StyledInnerNavGroup,
  InnerNavList, InnerNavItem, InnerNavItemToggle, InnerNavItemLink, ToggleIcon,
  ChildNav, ChildNavWrapper, ParentName, ChildNavList, ChildNavItem, ChildNavItemLink,
} from './styles';

export const InnerNavGroup = ({
  navData,
  isOpen,
  handleChildDrawer,
}) => {
  const [childItems, setChildItems] = useState(false);
  const [childNavOpen, setChildNavOpen] = useState(false);
  const [activeParent, setActiveParent] = useState(null);
  const { checkSeasonality } = useSeason();
  const animationDuration = 500; // milliseconds
  const filterLinks = useCallback(
    (links) => ((links)
      ? links?.filter((p) => checkSeasonality(p.season) && !p.isHideFromNav)
      : []),
    [checkSeasonality],
  );
  const isShowDrawer = useCallback((item) => filterLinks(item.childPages)?.length > 0
    && !item.isHideFromNav
    && !item.isOpenChildrenInModals
    && !item.isShowBackBtnOnChildren
    && !item.isHideChildrenFromNav, [filterLinks]);

  // this is setup with two states to make the animation
  // of the child nav drawer a little cleaner
  const childNavHandler = useCallback((s) => {
    if (!s) {
      setChildNavOpen(false);
      setTimeout(() => {
        setChildItems(s);
      }, animationDuration);
    } else if (s?.navRelationships?.navigationOverrides?.length > 0) {
      setChildItems(s?.navRelationships?.navigationOverrides);
      handleChildDrawer({
        parentName: getLevelName(s),
        items: filterLinks(s?.navRelationships?.navigationOverrides),
      });
      setChildNavOpen(true);
    } else {
      setChildItems(s?.childPages);
      handleChildDrawer({
        parentName: getLevelName(s),
        items: filterLinks(s?.childPages),
      });
      setChildNavOpen(true);
    }
  }, [handleChildDrawer, filterLinks]);

  useEffect(() => {
    if (!isOpen) {
      childNavHandler(false);
    }
  }, [isOpen, childNavHandler]);

  return (
    <StyledInnerNavGroup $isOpen={isOpen}>
      <InnerNavList>
        {filterLinks(navData).map((item, idx) => (
          <InnerNavItem id={`${activeParent} Child ${idx + 1}`}>
            {(isShowDrawer(item)) ? (
              <InnerNavItemToggle
                ref={(node) => node && (childNavOpen
                  ? node.setAttribute('inert', '')
                  : node.removeAttribute('inert')
                )}
                onClick={() => {
                  childNavHandler(item);
                  setActiveParent(getLevelName(item));
                }}
                aria-label={`Open ${getLevelName(item)} Child Item Drawer`}
              >
                {getLevelName(item)}
                <ToggleIcon
                  className="inner-nav-item-icon"
                  name="ui-arrow-right"
                  $animDuration={animationDuration}
                />
              </InnerNavItemToggle>
            ) : (
              <InnerNavItemLink
                ref={(node) => node && (childNavOpen
                  ? node.setAttribute('inert', '')
                  : node.removeAttribute('inert')
                )}
                href={getLevelLink((isLevelLinkNormal(item))
                  ? item
                  : item?.childPages.filter((page) => checkSeasonality(page.season))[0])}
                ariaLabel={`Go to ${getLevelName(item)} Page`}
              >
                {getLevelName(item)}
                <ToggleIcon
                  className="inner-nav-item-icon"
                  name="ui-arrow-right"
                  $animDuration={animationDuration}
                />
              </InnerNavItemLink>
            )}
          </InnerNavItem>
        ))}
      </InnerNavList>
      <ChildNav
        $isOpen={childNavOpen}
        $animDuration={animationDuration}
        ref={(node) => node && (!childNavOpen
          ? node.setAttribute('inert', '')
          : node.removeAttribute('inert')
        )}
      >
        <ChildNavWrapper
          $isOpen={childNavOpen}
          $animDuration={animationDuration}
        >
          <ParentName
            onClick={() => childNavHandler(false)}
            aria-label={`Close inner navigation drawer and return to ${activeParent} Navigation`}
            ref={(node) => node && (!childNavOpen
              ? node.setAttribute('inert', '')
              : node.removeAttribute('inert')
            )}
          >
            <Icon
              className="parent-name-icon"
              name="ui-arrow-left"
            />
            {activeParent}
          </ParentName>
          <ChildNavList>
            {filterLinks(childItems).map((childItem) => (
              <ChildNavItem>
                <ChildNavItemLink href={getLevelLink(childItem)} ariaLabel={`Navigate to ${getLevelName(childItem)} Page`}>
                  {getLevelName(childItem)}
                </ChildNavItemLink>
              </ChildNavItem>
            ))}
          </ChildNavList>
        </ChildNavWrapper>
      </ChildNav>
    </StyledInnerNavGroup>
  );
};

InnerNavGroup.propTypes = {
  navData: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    map: PropTypes.func,
    filter: PropTypes.func,
  })).isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleChildDrawer: PropTypes.func,
};

InnerNavGroup.defaultProps = {
  handleChildDrawer: () => {},
};
